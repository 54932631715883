import React, { Component } from "react"
import classNames from "classnames"
import {
  isMobileOnly,
  isBrowser,
  isMobile,
  withOrientationChange
} from "react-device-detect"
import PlayPause from "./Sizzle/playPause.js"

import SizzleMp4 from "../../src/videos/desktop_sizzle.mp4"
import MobileSizzleMp4 from "../../src/videos/mobile_sizzle.mp4"

const mobileVidUrls = [
  {
    src: MobileSizzleMp4,
    type: "video/mp4"
  }
]

const defaultVidUrls = [
  {
    src: SizzleMp4,
    type: "video/mp4"
  }
]

class SizzleVideo extends Component {
  constructor(props) {
    super(props)
    this.handlePlayPause = this.handlePlayPause.bind(this)
    this.state = {
      playing: true,
      isPhonePortrait: 0,
      vidUrl:
        isMobile && !this.props.isLandscape ? mobileVidUrls : defaultVidUrls,
      hidden: true,
      isLandscape: this.props.isLandscape,
      isPortrait: this.props.isPortrait,
      isMobileOnly: isMobileOnly
    }
    this.vidRef = React.createRef()
    this.playerWrapper = {}
    this.resize = this.resize.bind(this)
    this.handlePlayPause = this.handlePlayPause.bind(this)
  }
  componentDidMount() {
    const video = document.querySelector("video")
    video.addEventListener("mousedown", e => {
      requestAnimationFrame(() => {})
    })
    let underTablet = window.innerWidth < 576
    if (underTablet == true) {
      this.setState({
        hidden: false,
        isMobileOnly: isMobileOnly,
        isLandscape: this.props.isLandscape
      })
    } else {
      this.playerWrapper = {
        position: "relative"
      }
      this.setState({
        hidden: false,
        isMobileOnly: isMobileOnly,
        isLandscape: this.props.isLandscape
      })
    }
  }

  componentDidUpdate(prevState) {
    if (isMobileOnly) {
      if (this.props.isLandscape !== this.state.isLandscape) {
        if (this.props.isLandscape) {
          this.setState({
            vidUrl: defaultVidUrls,
            isLandscape: this.props.isLandscape,
            isPortrait: this.props.isPortrait
          })
        } else {
          this.setState({
            vidUrl: mobileVidUrls,
            isLandscape: this.props.isLandscape,
            isPortrait: this.props.isPortrait
          })
        }
      }
    }
  }

  resize(override) {
    if (this.props.isLandscape || isBrowser) {
      this.setState({ vidUrl: defaultVidUrls })
    } else {
      this.setState({ vidUrl: mobileVidUrls })
    }
  }

  handlePlayPause() {
    if (this.state.playing) {
      this.vidRef.current.pause()
      this.setState({ playing: false })
    } else {
      this.vidRef.current.play()
      this.setState({ playing: true })
    }
  }

  render() {
    var reactPlayerStyle = {
      top: 0,
      left: 0,
      display: "flex"
    }

    let homePlayerClasses = classNames({
      hidden: this.state.hidden,
      "test-1 home-sizzle": true
    })

    return (
      <div className={homePlayerClasses} style={{ position: "relative" }}>
        <video
          style={{ display: "block" }}
          ref={this.vidRef}
          className="sizzle"
          width="100%"
          height=""
          playsInline
          loop
          muted
          autoPlay
          controls={false}
        >
          {this.state.vidUrl.map(video => {
            return <source key={video.src} src={video.src} type={video.type} />
          })}
        </video>

        <PlayPause
          language={this.props.language ? this.props.language : null}
          handlePlayPause={this.handlePlayPause}
          videoPlaying={this.state.playing}
        />
      </div>
    )
  }
}

const VideoWrapped = withOrientationChange(SizzleVideo)

export { VideoWrapped }
